import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ArrowRight from "../components/svg/arrowRight"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Cta from "../components/cta"

const JcPage = () => (
    <Layout>
        <Seo 
            title="Startup development case study" 
            description="Case study of the startup development project completed for Joycards"
        />

        <Hero
            h1="B2C startup development case study"  
            h2="Joycards"
        />

        <section className="bg-purple section-pad-sm">
            <div className="container" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/joycards-main.png"
                        alt="Screens of the Joycards website"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>
        </section>

        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>Joycards is a <a href="https://joy.cards">group video greetings card</a> platform that enables people to group together to create a digital greeting card that is filled with personal video messages from all the contributors to the card.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/startup-web-designers/" className="btn btn-lg btn-line">Web design</Link>
                                    <Link to="/web-development/" className="btn btn-lg btn-line">Web development</Link>
                                    <Link to="/seo-for-startups/" className="btn btn-lg btn-line">Search engine optimisation</Link>
                                    <Link to="/startup-web-developers/" className="btn btn-lg btn-line">Startup development</Link>
                                    <Link to="/app-developer-sydney/" className="btn btn-lg btn-line">App development</Link>
                                    <Link to="/aws-infrastructure/" className="btn btn-lg btn-line">AWS infrastructure</Link>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h4>The work</h4>
                                    <p>Launch Lab designed and developed the website, the web application and the mobile apps.</p>
                                    <p>We also provided all SEO research and implementation including landing page development and copywriting.</p>
                                    <p>With over 30,000 videos uploaded, and thousands of cards sent so far, the platform continues to grow around the world.</p>
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>View the Joycards website.</p>
                                    <a href="https://joy.cards/" className="btn btn-lg btn-purple">Joycards</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            text="Get an obligation free startup development quote from an experienced local Australian startup development team."
        />
    </Layout>
)

export default JcPage